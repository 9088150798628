<template>
  <tr v-if="authUserIsAdmin">
    <!-- NAME & INFO -->
    <td class="align-middle">
      <div>{{ fullname }}</div>
      <div v-if="coachesData" class="smaller text-muted">
        <strong>Coached by:</strong> {{ coachesData }}
      </div>
      <div v-if="clientsData" class="smaller text-muted">
        <strong>Clients ({{ clientsCount }}):</strong> {{ clientsData }}
      </div>
      <div
        v-if="coaches && !coaches.length && clients && !clients.length"
        class="smaller text-muted"
      >No data available</div>
    </td>

    <!-- EMAIL -->
    <td class="align-middle">{{ user.email }}</td>

    <!-- TODO: DEPRECATED -->
    <!-- PLAN -->
    <td class="align-middle">
      <em v-if="user.capacity === -1">Unlimited</em>
      <div v-else>
        <div class="fw-bold">{{ user.planName }}</div>
        <div v-if="user.planDescription !== ''">{{ user.planDescription }}</div>
        <div>A$ {{ user.price }} <span class="smaller text-muted">per month</span></div>
      </div>
    </td>

    <!-- CLIENTS COUNT -->
    <td class="align-middle">{{ user.clientsCount }}</td>

    <!-- JOIN DATE -->
    <td class="align-middle">
      <div>{{ user.createdAt | dateReadableShort }}</div>
      <div class="smaller text-muted">{{ user.createdAt | dateRelative }}</div>
    </td>

    <!-- ACTION -->
    <td class="align-middle text-end">
      <div
        v-if="isLoading"
        class="spinner-border spinner-border-sm text-secondary mx-1"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>

      <BootstrapDropdown v-if="!isLoading">
        <li
          @click="getInfo"
          class="dropdown-item small"
          role="button"
        >
          <fa-icon :icon="['fas', 'info-circle']" class="me-1"></fa-icon>
          Get info
        </li>
        <li
          @click="showMessageModal = true"
          class="dropdown-item small"
          role="button"
        >
          <fa-icon :icon="['fas', 'envelope']" class="me-1"></fa-icon>
          Send message
        </li>
      </BootstrapDropdown>

      <portal to="modal">
        <transition name="modal">
          <MessageModal
            @close="showMessageModal = false"
            v-model="showMessageModal"
            v-if="showMessageModal"
            :user="user"
          ></MessageModal>
        </transition>
      </portal>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex';
import { httpGet } from '@/core/http';

export default {
  name: 'CoachListItem',
  components: {
    BootstrapDropdown: () => import('@/components/bootstrap/BootstrapDropdown'),
    MessageModal: () => import('@/components/modal/MessageModal'),
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters('auth', ['authUserIsAdmin']),
    fullname() {
      const { firstName, middleName, lastName } = this.user;
      return [firstName, middleName, lastName].join(' ');
    },
    coachesData() {
      const { coaches } = this;
      if (!coaches || !coaches.length) return null;
      return coaches.map((u) => {
        const { firstName, middleName, lastName } = u;
        return [firstName, middleName, lastName].join(' ');
      }).join(', ');
    },
    clientsData() {
      const { clients } = this;
      if (!clients || !clients.length) return null;
      return clients.map((u) => {
        const { firstName, middleName, lastName } = u;
        return [firstName, middleName, lastName].join(' ');
      }).join(', ');
    },
    clientsCount() {
      const { clients } = this;
      if (!clients) return 0;
      return clients.length;
    },
  },
  methods: {
    async getInfo() {
      this.isLoading = true;
      try {
        const { userId } = this.user;
        const { data: { coaches, clients } } = await httpGet(`/admin/user/${userId}`);
        this.coaches = coaches;
        this.clients = clients;
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isLoading = false;
      }
    },
    async findClients() {
      this.isLoading = true;
    },
  },
  data() {
    return {
      isLoading: false,
      coaches: null,
      clients: null,
      showMessageModal: false,
    };
  },
};
</script>
